<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-3">
        Citric acid is a triprotic acid that is naturally occurring in citrus fruits. It is also, as
        the name suggests, an important component of the citric acid cycle. It is a triprotic acid
        with the chemical formula
        <chemical-latex content="C6H8O7(aq)" />
        . If a
        <number-value :value="concentration" />
        <stemble-latex :content="'$\\text{M}$'" />
        aqueous solution of citric acid is prepared, calculate the
        <stemble-latex :content="'$\\text{pH}$'" />
        and the concentrations of all species at equilibrium.
      </p>
      <p>The three acid dissociation steps are as follows:</p>

      <table class="mb-4 ml-4">
        <tr style="height: 40px">
          <td style="vertical-align: top" class="pr-3">
            <chemical-latex
              style="font-size: 13px"
              content="C6H8O7(aq) + H2O(l) <=> C6H7O7^-(aq) + H3O^+(aq)"
            />
          </td>
          <td style="vertical-align: top">
            <stemble-latex
              :content="'$\\qquad\\text{K}_{\\text{a}_{\\text{1}}}=6.92\\times10^{-3}$'"
              style="font-size: 13px"
            />
          </td>
        </tr>
        <tr style="height: 40px">
          <td style="vertical-align: top" class="pr-3">
            <chemical-latex
              style="font-size: 13px"
              content="C6H7O7^-(aq) + H2O(l) <=> C6H6O7^{2-}(aq) + H3O^+(aq)"
            />
          </td>
          <td style="vertical-align: top">
            <stemble-latex
              :content="'$\\qquad\\text{K}_{\\text{a}_{\\text{2}}}=6.17\\times10^{-8}$'"
              style="font-size: 13px"
            />
          </td>
        </tr>
        <tr style="height: 40px">
          <td style="vertical-align: top" class="pr-3">
            <chemical-latex
              style="font-size: 13px"
              content="C6H6O7^{2-}(aq) + H2O(l) <=> C6H5O7^{3-}(aq) + H3O^+(aq)"
            />
          </td>
          <td style="vertical-align: top">
            <stemble-latex
              :content="'$\\qquad\\text{K}_{\\text{a}_{\\text{3}}}=4.79\\times10^{-13}$'"
              style="font-size: 13px"
            />
          </td>
        </tr>
      </table>

      <calculation-input
        v-model="inputs.c6h8o7"
        :prepend-text="'$\\ce{[C6H8O7]}$'"
        :append-text="'$\\text{M}$'"
        :disabled="!allowEditing"
      />
      <br />

      <calculation-input
        v-model="inputs.c6h7o7"
        :prepend-text="'$\\ce{[C6H7O7^-]}$'"
        :append-text="'$\\text{M}$'"
        :disabled="!allowEditing"
      />
      <br />

      <calculation-input
        v-model="inputs.c6h6o7"
        :prepend-text="'$\\ce{[C6H6O7^{2-}]}$'"
        :append-text="'$\\text{M}$'"
        :disabled="!allowEditing"
      />
      <br />

      <calculation-input
        v-model="inputs.c6h5o7"
        :prepend-text="'$\\ce{[C6H5O7^{3-}]}$'"
        :append-text="'$\\text{M}$'"
        :disabled="!allowEditing"
      />
      <br />

      <calculation-input
        v-model="inputs.h3o"
        :prepend-text="'$\\ce{[H3O^+]}$'"
        :append-text="'$\\text{M}$'"
        :disabled="!allowEditing"
      />
      <br />

      <calculation-input
        v-model="inputs.pH"
        :prepend-text="'$\\text{pH}$'"
        :disabled="!allowEditing"
      />
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import ChemicalLatex from '../displayers/ChemicalLatex';
import CalculationInput from '../inputs/CalculationInput';
import NumberValue from '../NumberValue';
import StembleLatex from '@/tasks/components/StembleLatex';

export default {
  name: 'Question162',
  components: {
    StembleLatex,
    ChemicalLatex,
    CalculationInput,
    NumberValue,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      // Put a list of inputs or computed properties here to be sent for marking
      inputs: {
        c6h8o7: null,
        c6h7o7: null,
        c6h6o7: null,
        c6h5o7: null,
        h3o: null,
        pH: null,
      },
    };
  },
  computed: {
    concentration() {
      return this.taskState.getValueBySymbol('concentration').content;
    },
  },
};
</script>
